import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/config";
import Products from "../Products/ProCard";
import "./ProductList.css";
import { useTranslation } from "react-i18next";

const ProductList = ({
  products: initialProducts,
  setIsCartOpen,
  cartItems,
  setCartItems,
  isCartOpen,
}) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState(initialProducts || []);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const categoriesRef = collection(db, "categories");
        const querySnapshot = await getDocs(categoriesRef);

        const categoriesList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));

        setCategories([
          { id: "all", name: t("categories.all") },
          ...categoriesList,
        ]);
      } catch (err) {
        console.error("Error fetching categories:", err);
        setError(t("product.error"));
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [t]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log("Fetching products from Firestore...");
        let productsRef = collection(db, "products");
        let q;

        if (selectedCategory === "all") {
          q = query(productsRef);
        } else {
          q = query(productsRef, where("categoryId", "==", selectedCategory));
        }

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          console.log("No products found in Firestore");
          setProducts([]);
          return;
        }

        const productsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();

            // Prices koleksiyonunu al
            const pricesRef = collection(doc.ref, "prices");
            const pricesSnapshot = await getDocs(pricesRef);

            // Active olan price'ı bul
            let activePrice = null;
            pricesSnapshot.forEach((priceDoc) => {
              const priceData = priceDoc.data();
              if (priceData.active === true) {
                activePrice = {
                  id: priceDoc.id,
                  currency: priceData.currency,
                  unit_amount: priceData.unit_amount,
                  type: priceData.type,
                };
              }
            });

            // Fiyatı cent'ten dolara çevir
            const price = activePrice ? activePrice.unit_amount / 100 : 0;

            return {
              id: doc.id,
              name: data.name || "",
              price: price,
              currency: activePrice?.currency || "usd",
              description: data.description || "",
              image: data.image || "",
              categoryId: data.categoryId || "",
              images: data.images || [],
              videoUrl: data.videoUrl || null,
              priceId: activePrice?.id,
            };
          })
        );

        console.log("Fetched products:", productsData);
        setProducts(productsData);
        setError(null);
      } catch (err) {
        console.error("Error fetching products:", err);
        setError(t("product.error"));
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [selectedCategory, t]);

  if (loading) {
    return (
      <div className="product-list-wrapper">
        <aside className="sidebar">
          <div className="category-filter loading-state">
            <div className="category-skeleton"></div>
            <div className="category-skeleton"></div>
            <div className="category-skeleton"></div>
          </div>
        </aside>
        <div className="product-list-container">
          <div className="loading">{t("product.loading")}</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="product-list-wrapper">
        <div className="error-container">
          <div className="error">
            <i className="fas fa-exclamation-circle"></i>
            <p>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="product-list-wrapper">
      <aside className="sidebar">
        <div className="category-filter">
          <h3>
            <i className="fas fa-tags"></i>
            {t("categories.title")}
          </h3>
          <div className="category-list">
            {categories.map((category) => (
              <button
                key={category.id}
                className={`category-btn ${
                  selectedCategory === category.id ? "active" : ""
                }`}
                onClick={() => setSelectedCategory(category.id)}
              >
                {selectedCategory === category.id && (
                  <i className="fas fa-check"></i>
                )}
                <span>{category.name}</span>
              </button>
            ))}
          </div>
        </div>
      </aside>

      <div className="product-list-container">
        {!products || products.length === 0 ? (
          <div className="no-products">
            <i className="fas fa-box-open"></i>
            <p>{t("product.noProducts")}</p>
          </div>
        ) : (
          <Products
            products={products}
            setIsCartOpen={setIsCartOpen}
            cartItems={cartItems}
            setCartItems={setCartItems}
            isCartOpen={isCartOpen}
          />
        )}
      </div>
    </div>
  );
};

export default ProductList;

import React, { useState, useEffect } from "react";
import { db, storage } from "../../../firebase/config";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  orderBy,
  query,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import "./HeroBanners.css";

const HeroBanners = () => {
  const [banners, setBanners] = useState([]);
  const [products, setProducts] = useState([]);
  const [newBanner, setNewBanner] = useState({
    title: "",
    subtitle: "",
    image: null,
    productId: "",
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  // Ürünleri getir
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsRef = collection(db, "products");
        const querySnapshot = await getDocs(productsRef);
        const productsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Banner'ları getir fonksiyonu
  const fetchBanners = async () => {
    try {
      const bannersRef = collection(db, "heroBanners");
      const q = query(bannersRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);
      const bannersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBanners(bannersData);
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  };

  // Banner'ları yükle
  useEffect(() => {
    fetchBanners();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setNewBanner((prev) => ({
        ...prev,
        image: e.target.files[0],
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!newBanner.image) {
        alert("Lütfen bir görsel seçin");
        return;
      }

      const storageRef = ref(storage, `heroBanners/${newBanner.image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, newBanner.image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Upload error:", error);
        },
        async () => {
          const imageUrl = await getDownloadURL(storageRef);

          // Firestore'a banner ekle - productId de ekleniyor
          await addDoc(collection(db, "heroBanners"), {
            title: newBanner.title,
            subtitle: newBanner.subtitle,
            image: imageUrl,
            productId: newBanner.productId, // Ürün ID'si eklendi
            createdAt: new Date(),
          });

          setNewBanner({
            title: "",
            subtitle: "",
            image: null,
            productId: "", // Reset product ID
          });
          setUploadProgress(0);
          setLoading(false);

          // Banner listesini güncelle
          fetchBanners();
        }
      );
    } catch (error) {
      console.error("Error adding banner:", error);
      setLoading(false);
    }
  };

  const handleDelete = async (bannerId, imageUrl) => {
    try {
      // Firestore'dan banner'ı sil
      await deleteDoc(doc(db, "heroBanners", bannerId));

      // Storage'dan görseli sil
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);

      // Banner listesini güncelle
      setBanners((prev) => prev.filter((banner) => banner.id !== bannerId));
    } catch (error) {
      console.error("Error deleting banner:", error);
    }
  };

  return (
    <div className="hero-banners-container">
      <h2>Hero Banner Yönetimi</h2>

      <form onSubmit={handleSubmit} className="banner-form">
        <div className="form-group">
          <label>Başlık</label>
          <input
            type="text"
            value={newBanner.title}
            onChange={(e) =>
              setNewBanner((prev) => ({ ...prev, title: e.target.value }))
            }
            placeholder="Banner başlığı"
            required
          />
        </div>

        <div className="form-group">
          <label>Alt Başlık</label>
          <input
            type="text"
            value={newBanner.subtitle}
            onChange={(e) =>
              setNewBanner((prev) => ({ ...prev, subtitle: e.target.value }))
            }
            placeholder="Banner alt başlığı"
            required
          />
        </div>

        <div className="form-group">
          <label>Bağlantılı Ürün</label>
          <select
            value={newBanner.productId}
            onChange={(e) =>
              setNewBanner((prev) => ({ ...prev, productId: e.target.value }))
            }
            required
          >
            <option value="">Ürün Seçin</option>
            {products.map((product) => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Görsel</label>
          <input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            required
          />
          {uploadProgress > 0 && (
            <div className="progress-bar">
              <div
                className="progress"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
          )}
        </div>

        <button type="submit" disabled={loading}>
          {loading ? "Yükleniyor..." : "Banner Ekle"}
        </button>
      </form>

      <div className="banners-list">
        <h3>Mevcut Bannerlar</h3>
        <div className="banners-grid">
          {banners.map((banner) => (
            <div key={banner.id} className="banner-item">
              <img src={banner.image} alt={banner.title} />
              <div className="banner-info">
                <h4>{banner.title}</h4>
                <p>{banner.subtitle}</p>
                <p className="linked-product">
                  Bağlantılı Ürün:{" "}
                  {products.find((p) => p.id === banner.productId)?.name ||
                    "Ürün Seçilmedi"}
                </p>
                <button
                  onClick={() => handleDelete(banner.id, banner.image)}
                  className="delete-button"
                >
                  Sil
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeroBanners;

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  addDoc,
  increment,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { useAuth } from "../../contexts/AuthContext";
import "./Checkout.css";
import { httpsCallable } from "firebase/functions";
import { getFunctions } from "firebase/functions";
import { getPaymentConfig } from "../../utils/payment";
import { useTranslation } from "react-i18next";
import { useCart } from "../../contexts/CartContext";

const stripePromise = loadStripe(
  "pk_test_51QISSYFsce16XFVAypUOJeDc0ENXp0EuKdCnCkm6s6j81iICQJWwTBwISeFzfJuFBKImYCaGhPOuPZvjpEBPXMPy00GcT1T8Dh"
);

// Kart bilgileri için stil seçenekleri
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

// Ödeme formu bileşeni
const CheckoutForm = ({
  formData,
  cartItems,
  total,
  shippingCost,
  shippingMethod,
  selectedRegion,
  onClose,
  onComplete,
  saveUserData,
  handleInputChange,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { currentUser } = useAuth();
  const { clearCart } = useCart();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Ülkeye göre varsayılan ödeme yöntemini belirle
  const [paymentMethod, setPaymentMethod] = useState(
    formData.country === "TR" ? "iyzico" : "stripe"
  );

  // Ülke değiştiğinde ödeme yöntemini güncelle
  useEffect(() => {
    setPaymentMethod(formData.country === "TR" ? "iyzico" : "stripe");
  }, [formData.country]);

  // İyzico ödeme işlemi
  const handleIyzicoPayment = async () => {
    try {
      const functions = getFunctions();
      const createIyzicoPayment = httpsCallable(
        functions,
        "createIyzicoPayment"
      );

      const paymentData = {
        locale: "tr",
        price: total + shippingCost,
        paidPrice: total + shippingCost,
        currency: "TRY",
        basketId: `basket_${Date.now()}`,
        paymentGroup: "PRODUCT",
        buyer: {
          id: currentUser.uid,
          name: formData.firstName,
          surname: formData.lastName,
          email: formData.email,
          identityNumber: "11111111111", // Geçici TC No
          registrationAddress: formData.address,
          city: formData.city,
          country: formData.country,
          ip: "85.34.78.112", // Müşteri IP adresi alınmalı
        },
        shippingAddress: {
          address: formData.address,
          zipCode: formData.postalCode,
          contactName: `${formData.firstName} ${formData.lastName}`,
          city: formData.city,
          country: formData.country,
        },
        billingAddress: {
          address: formData.address,
          zipCode: formData.postalCode,
          contactName: `${formData.firstName} ${formData.lastName}`,
          city: formData.city,
          country: formData.country,
        },
        basketItems: cartItems.map((item) => ({
          id: item.id,
          name: item.name,
          category1: item.category || "Sanat",
          itemType: "PHYSICAL",
          price: item.price,
        })),
      };

      const result = await createIyzicoPayment(paymentData);

      if (result.data?.status === "success") {
        // İyzico form içeriğini sayfaya ekle
        const div = document.getElementById("iyzico-checkout-form");
        if (div) {
          div.innerHTML = result.data.checkoutFormContent;
        }
      } else {
        throw new Error(result.data?.errorMessage || "Ödeme başlatılamadı");
      }
    } catch (error) {
      console.error("İyzico ödeme hatası:", error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Zorunlu alanları kontrol et
      const requiredFields = [
        "firstName",
        "lastName",
        "email",
        "phone",
        "address",
        "city",
        "country",
        "postalCode",
      ];
      const missingFields = requiredFields.filter((field) => !formData[field]);

      if (missingFields.length > 0) {
        throw new Error(
          `${t("checkout.errors.required")}: ${missingFields.join(", ")}`
        );
      }

      if (paymentMethod === "stripe") {
        // Stripe ödeme işlemi
        if (!stripe || !elements) {
          throw new Error("Stripe yüklenemedi.");
        }

        // Stripe'dan ödeme niyeti oluştur
        const functions = getFunctions();
        const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
        const paymentIntentResult = await createPaymentIntent({
          amount: Math.round((total + shippingCost) * 100), // Stripe kuruş cinsinden çalışır
          currency: 'try'
        });

        const { clientSecret } = paymentIntentResult.data;

        // Kart bilgileriyle ödemeyi onayla
        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                name: `${formData.firstName} ${formData.lastName}`,
                email: formData.email,
                phone: formData.phone,
                address: {
                  line1: formData.address,
                  city: formData.city,
                  postal_code: formData.postalCode,
                  country: formData.country,
                },
              },
            },
          }
        );

        if (stripeError) {
          throw new Error(stripeError.message);
        }

        if (paymentIntent.status === 'succeeded') {
          // Siparişi veritabanına kaydet
          await saveOrder(paymentIntent.id);
          await saveUserData();
          clearCart();
          onComplete();
        }
      } else if (paymentMethod === "iyzico") {
        await handleIyzicoPayment();
      }
    } catch (error) {
      console.error("Ödeme hatası:", error);
      setError(error.message || t("checkout.errors.paymentFailed"));
    } finally {
      setLoading(false);
    }
  };

  // Siparişi veritabanına kaydetmek için yardımcı fonksiyon
  const saveOrder = async (paymentId) => {
    try {
      const orderData = {
        userId: currentUser.uid,
        items: cartItems,
        total: total + shippingCost,
        shippingAddress: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          address: formData.address,
          city: formData.city,
          country: formData.country,
          postalCode: formData.postalCode,
          phone: formData.phone,
        },
        paymentId: paymentId,
        paymentMethod: paymentMethod,
        status: 'paid',
        createdAt: new Date().toISOString()
      };

      const ordersRef = collection(db, "orders");
      await addDoc(ordersRef, orderData);
    } catch (error) {
      console.error("Sipariş kaydedilirken hata:", error);
      throw new Error("Sipariş kaydedilemedi");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      {/* Teslimat Bilgileri */}
      <div className="form-section">
        <h3>{t("checkout.deliveryInfo")}</h3>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="firstName">{t("checkout.firstName")}</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          {/* ... diğer form alanları ... */}
        </div>
      </div>

      {/* Ödeme Yöntemi Seçimi */}
      <div className="form-section">
        <h3>{t("checkout.paymentInfo")}</h3>
        <div className="payment-options">
          <label
            className={`payment-method ${
              formData.country === "TR" ? "" : "disabled"
            }`}
          >
            <input
              type="radio"
              name="paymentMethod"
              value="iyzico"
              checked={paymentMethod === "iyzico"}
              onChange={(e) => setPaymentMethod(e.target.value)}
              disabled={formData.country !== "TR"}
            />
            <span>İyzico</span>
            <img src="/iyzico-logo.png" alt="İyzico" />
          </label>

          <label className="payment-method">
            <input
              type="radio"
              name="paymentMethod"
              value="stripe"
              checked={paymentMethod === "stripe"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <span>Kredi/Banka Kartı</span>
            <img src="/card-brands.png" alt="Kredi Kartı" />
          </label>
        </div>

        {/* Stripe Kart Bilgileri Formu */}
        {paymentMethod === "stripe" && (
          <div className="card-element-container">
            <label>{t("checkout.cardDetails")}</label>
            <CardElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={(e) => {
                if (e.error) {
                  setError(e.error.message);
                } else {
                  setError(null);
                }
              }}
            />
            {error && <div className="error-message">{error}</div>}
          </div>
        )}

        {/* İyzico Ödeme Formu */}
        {paymentMethod === "iyzico" && (
          <div id="iyzico-checkout-form" className="iyzico-form">
            {/* İyzico form buraya yüklenecek */}
          </div>
        )}
      </div>

      {/* Sipariş Özeti */}
      <div className="order-summary">
        <h3>{t("checkout.orderSummary")}</h3>
        <div className="summary-details">
          <div className="summary-row">
            <span>{t("checkout.subtotal")}</span>
            <span>{formatCurrency(total)}</span>
          </div>
          <div className="summary-row">
            <span>{t("checkout.shipping.cost")}</span>
            <span>{formatCurrency(shippingCost)}</span>
          </div>
          <div className="summary-row total">
            <span>{t("checkout.total")}</span>
            <span>{formatCurrency(total + shippingCost)}</span>
          </div>
        </div>
      </div>

      {/* Ödeme Butonları */}
      <div className="form-actions">
        <button type="button" onClick={onClose} className="back-button">
          {t("checkout.backToCart")}
        </button>
        <button
          type="submit"
          className="payment-button"
          disabled={!stripe || loading}
        >
          {loading ? (
            <span className="loading-spinner"></span>
          ) : (
            t("checkout.completePayment")
          )}
        </button>
      </div>
    </form>
  );
};

const COUNTRY_REGIONS = {
  TR: "domestic",
  DE: "europe",
  FR: "europe",
  GB: "europe",
  IT: "europe",
  ES: "europe",
  NL: "europe",
  US: "americas",
  CA: "americas",
  BR: "americas",
  JP: "asia",
  KR: "asia",
  CN: "asia",
  IN: "asia",
};

// Para birimi formatlamak için yardımcı fonksiyon
const formatCurrency = (amount, currency = "TRY") => {
  const formatter = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(amount);
};

// Kargo fiyatları için sabit değerler
const SHIPPING_RATES = {
  domestic: {
    base: 49.9,
    currency: "TRY",
  },
  international: {
    europe: {
      base: 249.9,
      currency: "EUR",
    },
    americas: {
      base: 349.9,
      currency: "USD",
    },
    asia: {
      base: 299.9,
      currency: "USD",
    },
    other: {
      base: 399.9,
      currency: "USD",
    },
  },
};

// Ana Checkout bileşeni
const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const { clearCart } = useCart();
  const { t } = useTranslation();

  // State tanımlamaları
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: currentUser?.email || "",
    phone: "",
    address: "",
    city: "",
    country: "TR",
    postalCode: "",
  });

  // Location state kontrolü
  const cartItems = location.state?.items || [];
  const total = location.state?.total || 0;

  const [shippingMethod, setShippingMethod] = useState("domestic");
  const [selectedRegion, setSelectedRegion] = useState("europe");
  const [shippingCost, setShippingCost] = useState(0);

  // Kargo ücretini hesapla
  const calculateShipping = useCallback((items, method, region = "") => {
    try {
      if (method === "domestic") {
        return SHIPPING_RATES.domestic.base;
      } else {
        // region değerinin geçerli olduğundan emin ol
        const validRegion = SHIPPING_RATES.international[region] ? region : 'other';
        return SHIPPING_RATES.international[validRegion].base;
      }
    } catch (error) {
      console.error('Kargo ücreti hesaplama hatası:', error);
      return SHIPPING_RATES.international.other.base; // Varsayılan değer
    }
  }, []);

  // Ülke değiştiğinde kargo metodunu otomatik güncelle
  useEffect(() => {
    try {
      // Ülkeye göre bölgeyi belirle
      const region = COUNTRY_REGIONS[formData.country] || 'other';
      
      // Bölgeyi güncelle (europe, americas, asia, other)
      const shippingRegion = region === 'domestic' ? 'europe' : region;
      setSelectedRegion(shippingRegion);

      // Türkiye için domestic, diğer ülkeler için international
      const method = formData.country === 'TR' ? 'domestic' : 'international';
      setShippingMethod(method);

      // Kargo ücretini güncelle
      const cost = calculateShipping(cartItems, method, shippingRegion);
      setShippingCost(cost);
    } catch (error) {
      console.error('Kargo metodu güncelleme hatası:', error);
      // Varsayılan değerleri ayarla
      setSelectedRegion('other');
      setShippingMethod('international');
      setShippingCost(SHIPPING_RATES.international.other.base);
    }
  }, [formData.country, cartItems, calculateShipping]);

  // Form input değişikliklerini handle et
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Formu kapatma/iptal etme işlemi
  const handleClose = () => {
    // Kullanıcıyı sepet sayfasına yönlendir
    navigate("/cart");
  };

  // Kullanıcı bilgilerini kaydetme
  const saveUserData = async () => {
    try {
      if (!currentUser) return;

      const userRef = doc(db, "users", currentUser.uid);
      await setDoc(
        userRef,
        {
          shippingAddress: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            address: formData.address,
            city: formData.city,
            country: formData.country,
            postalCode: formData.postalCode,
            phone: formData.phone,
          },
          lastUpdated: new Date().toISOString(),
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Kullanıcı bilgileri kaydedilirken hata:", error);
    }
  };

  // Ödeme tamamlandığında
  const handleComplete = () => {
    clearCart();
    saveUserData(); // Kullanıcı bilgilerini kaydet
    navigate("/success");
  };

  // Kullanıcı bilgilerini yükle
  useEffect(() => {
    const loadUserData = async () => {
      if (!currentUser) return;

      try {
        const userRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Kayıtlı kullanıcı bilgilerini forma doldur
          setFormData((prev) => ({
            ...prev,
            firstName: userData.firstName || prev.firstName,
            lastName: userData.lastName || prev.lastName,
            email: userData.email || currentUser.email,
            phone: userData.phone || prev.phone,
            address: userData.shippingAddress?.address || prev.address,
            city: userData.shippingAddress?.city || prev.city,
            country: userData.shippingAddress?.country || prev.country,
            postalCode: userData.shippingAddress?.postalCode || prev.postalCode,
          }));
        }
      } catch (error) {
        console.error("Kullanıcı bilgileri yüklenirken hata:", error);
        setError("Kullanıcı bilgileri yüklenemedi");
      }
    };

    loadUserData();
  }, [currentUser]);

  // Loading durumu
  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  // Hata durumu
  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="checkout-page">
      <div className="checkout-container">
        <div className="checkout-left">
          <h2>{t("checkout.deliveryInfo")}</h2>
          <form className="delivery-form">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">{t("checkout.firstName")}</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">{t("checkout.lastName")}</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="email">{t("checkout.email")}</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                disabled
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">{t("checkout.phone")}</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="address">{t("checkout.address")}</label>
              <textarea
                id="address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="city">{t("checkout.city")}</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="postalCode">{t("checkout.postalCode")}</label>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="country">{t("checkout.country")}</label>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                required
              >
                <option value="TR">Türkiye</option>
                <option value="DE">Almanya</option>
                <option value="FR">Fransa</option>
                <option value="GB">İngiltere</option>
                <option value="US">Amerika Birleşik Devletleri</option>
                <option value="CA">Kanada</option>
                <option value="JP">Japonya</option>
                <option value="KR">Güney Kore</option>
                {/* ... diğer ülkeler ... */}
              </select>
            </div>
          </form>

          <div className="payment-section">
            <h2>{t("checkout.paymentInfo")}</h2>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                formData={formData}
                cartItems={cartItems}
                total={total}
                shippingCost={shippingCost}
                shippingMethod={shippingMethod}
                selectedRegion={selectedRegion}
                onClose={handleClose}
                onComplete={handleComplete}
                saveUserData={saveUserData}
                handleInputChange={handleInputChange}
              />
            </Elements>
          </div>

          <div className="shipping-options">
            <h3>{t("checkout.shipping.title")}</h3>

            <div className="shipping-method">
              <label
                className={`${shippingMethod === "domestic" ? "active" : ""} ${
                  formData.country !== "TR" ? "disabled" : ""
                }`}
              >
                <input
                  type="radio"
                  name="shipping"
                  value="domestic"
                  checked={shippingMethod === "domestic"}
                  onChange={(e) => setShippingMethod(e.target.value)}
                  disabled={formData.country !== "TR"}
                />
                <div>
                  <div>{t("checkout.shipping.domestic")}</div>
                  <div className="shipping-price">
                    {formatCurrency(
                      SHIPPING_RATES.domestic.base,
                      SHIPPING_RATES.domestic.currency
                    )}
                  </div>
                </div>
              </label>

              <label
                className={`${
                  shippingMethod === "international" ? "active" : ""
                } ${formData.country === "TR" ? "disabled" : ""}`}
              >
                <input
                  type="radio"
                  name="shipping"
                  value="international"
                  checked={shippingMethod === "international"}
                  onChange={(e) => setShippingMethod(e.target.value)}
                  disabled={formData.country === "TR"}
                />
                <div>
                  <div>{t("checkout.shipping.international")}</div>
                  <div className="shipping-price">
                    {formatCurrency(
                      SHIPPING_RATES.international[selectedRegion].base,
                      SHIPPING_RATES.international[selectedRegion].currency
                    )}
                  </div>
                </div>
              </label>
            </div>

            {shippingMethod === "international" && (
              <div className="region-selector">
                <select
                  value={selectedRegion}
                  onChange={(e) => setSelectedRegion(e.target.value)}
                  className="region-select"
                  disabled={true}
                >
                  <option value="europe">
                    {t("checkout.shipping.regions.europe")} -{" "}
                    {formatCurrency(
                      SHIPPING_RATES.international.europe.base,
                      SHIPPING_RATES.international.europe.currency
                    )}
                  </option>
                  <option value="americas">
                    {t("checkout.shipping.regions.americas")} -{" "}
                    {formatCurrency(
                      SHIPPING_RATES.international.americas.base,
                      SHIPPING_RATES.international.americas.currency
                    )}
                  </option>
                  <option value="asia">
                    {t("checkout.shipping.regions.asia")} -{" "}
                    {formatCurrency(
                      SHIPPING_RATES.international.asia.base,
                      SHIPPING_RATES.international.asia.currency
                    )}
                  </option>
                  <option value="other">
                    {t("checkout.shipping.regions.other")} -{" "}
                    {formatCurrency(
                      SHIPPING_RATES.international.other.base,
                      SHIPPING_RATES.international.other.currency
                    )}
                  </option>
                </select>
              </div>
            )}

            <div className="shipping-summary">
              <div className="shipping-cost">
                <span>{t("checkout.shipping.cost")}</span>
                <span>₺{shippingCost.toFixed(2)}</span>
              </div>
              <div className="estimated-delivery">
                <span>{t("checkout.shipping.estimatedDelivery")}</span>
                <span>
                  {shippingMethod === "domestic"
                    ? t("checkout.shipping.domesticDelivery")
                    : t("checkout.shipping.internationalDelivery")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="checkout-right">
          <div className="order-summary">
            <h2>{t("checkout.orderSummary")}</h2>
            <div className="price-summary">
              <div className="subtotal">
                <span>{t("checkout.subtotal")}</span>
                <span>{formatCurrency(total, "TRY")}</span>
              </div>

              <div className="shipping-cost">
                <span>{t("checkout.shipping.cost")}</span>
                <span>₺{shippingCost.toFixed(2)}</span>
              </div>

              <div className="estimated-delivery">
                <span>{t("checkout.shipping.estimatedDelivery")}</span>
                <span>
                  {shippingMethod === "domestic"
                    ? t("checkout.shipping.domesticDelivery")
                    : t("checkout.shipping.internationalDelivery")}
                </span>
              </div>

              <div className="total">
                <span>{t("checkout.total")}</span>
                <span>{formatCurrency(total + shippingCost, "TRY")}</span>
              </div>
            </div>
          </div>

          <div className="secure-checkout-info">
            <div className="secure-icons">🔒</div>
            <p>{t("checkout.securePaymentInfo")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase/config";
import "./ProductDetail.css";
import { loadStripe } from "@stripe/stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(
  "pk_test_51QISSYFsce16XFVAypUOJeDc0ENXp0EuKdCnCkm6s6j81iICQJWwTBwISeFzfJuFBKImYCaGhPOuPZvjpEBPXMPy00GcT1T8Dh"
);
const functions = getFunctions();

const ProductDetail = ({
  addToCart,
  setIsCartOpen,
  cartItems,
  setCartItems,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMediaIndex, setModalMediaIndex] = useState(0);
  const modalVideoRef = useRef(null);
  const videoRef = useRef(null);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const docRef = doc(db, "products", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log("Fetched product data:", data);

          const pricesSnapshot = await getDocs(
            collection(db, `products/${id}/prices`)
          );
          let priceData = null;

          if (!pricesSnapshot.empty) {
            const firstPriceDoc = pricesSnapshot.docs[0];
            priceData = {
              ...firstPriceDoc.data(),
            };
          }

          const sortedImages = [...(data.images || [])];
          if (data.thumbnailIndex !== undefined && sortedImages.length > 0) {
            const thumbnailImage = sortedImages.splice(
              data.thumbnailIndex,
              1
            )[0];
            sortedImages.unshift(thumbnailImage);
          }

          setProduct({
            ...data,
            price: priceData,
            allMedia: [
              ...(data.video ? [{ type: "video", url: data.video }] : []),
              ...sortedImages.map((img) => ({ type: "image", url: img })),
            ],
          });

          setCurrentMediaIndex(data.video ? 1 : 0);
        } else {
          setError("Ürün bulunamadı!");
        }
      } catch (error) {
        console.error("Error fetching product: ", error);
        setError("Ürün yüklenirken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleMediaClick = (index) => {
    setModalMediaIndex(index);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (modalVideoRef.current) {
      modalVideoRef.current.pause();
    }
  };

  const handleModalNavigation = (direction) => {
    if (!product?.allMedia) return;

    if (direction === "prev") {
      setModalMediaIndex((prev) =>
        prev === 0 ? product.allMedia.length - 1 : prev - 1
      );
    } else {
      setModalMediaIndex((prev) =>
        prev === product.allMedia.length - 1 ? 0 : prev + 1
      );
    }
  };

  const renderModalMedia = () => {
    if (!product?.allMedia) return null;
    const currentMedia = product.allMedia[modalMediaIndex];

    return (
      <div className="modal-media-container">
        {currentMedia.type === "video" ? (
          <video
            ref={modalVideoRef}
            src={currentMedia.url}
            className="modal-media video"
            controls
            autoPlay
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <img
            src={currentMedia.url}
            alt={product.name}
            className="modal-media image"
            onClick={(e) => e.stopPropagation()}
          />
        )}

        <button
          className="modal-nav prev"
          onClick={(e) => {
            e.stopPropagation();
            handleModalNavigation("prev");
          }}
        >
          ‹
        </button>
        <button
          className="modal-nav next"
          onClick={(e) => {
            e.stopPropagation();
            handleModalNavigation("next");
          }}
        >
          ›
        </button>

        <div className="modal-thumbnails">
          {product.allMedia.map((media, index) => (
            <div
              key={index}
              className={`modal-thumbnail ${
                modalMediaIndex === index ? "active" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setModalMediaIndex(index);
              }}
            >
              {media.type === "video" ? (
                <div className="video-thumbnail">
                  <video src={media.url} />
                  <span className="video-icon">▶</span>
                </div>
              ) : (
                <img src={media.url} alt="" />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderMedia = () => {
    if (!product?.allMedia?.length) return null;

    const currentMedia = product.allMedia[currentMediaIndex];

    return (
      <div className="media-container">
        {currentMedia.type === "video" ? (
          <video
            key={currentMedia.url}
            ref={videoRef}
            src={currentMedia.url}
            className="product-media video"
            autoPlay
            muted
            loop
            playsInline
            controls
            onClick={() => handleMediaClick(currentMediaIndex)}
            onError={(e) => console.error("Video error:", e)}
          />
        ) : (
          <img
            src={currentMedia.url}
            alt={product.name}
            className="product-media image"
            onClick={() => handleMediaClick(currentMediaIndex)}
            style={{ cursor: "pointer" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://via.placeholder.com/400x400?text=No+Image";
            }}
          />
        )}

        {product.allMedia.length > 1 && (
          <>
            <button
              className="media-nav prev"
              onClick={(e) => {
                e.preventDefault();
                setCurrentMediaIndex((prev) =>
                  prev === 0 ? product.allMedia.length - 1 : prev - 1
                );
              }}
            >
              ‹
            </button>
            <button
              className="media-nav next"
              onClick={(e) => {
                e.preventDefault();
                setCurrentMediaIndex((prev) =>
                  prev === product.allMedia.length - 1 ? 0 : prev + 1
                );
              }}
            >
              ›
            </button>
            <div className="media-dots">
              {product.allMedia.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${
                    currentMediaIndex === index ? "active" : ""
                  }`}
                  onClick={() => setCurrentMediaIndex(index)}
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  const handlePurchase = () => {
    if (!product) return;

    const newItem = {
      id: id,
      title: product.name,
      price: product.price?.unit_amount,
      image:
        product.allMedia?.[0]?.url ||
        "https://via.placeholder.com/400x400?text=No+Image",
      quantity: 1,
      currency: product.price?.currency || "USD",
    };

    const existingItemIndex = cartItems.findIndex((item) => item.id === id);

    if (existingItemIndex !== -1) {
      const updatedItems = [...cartItems];
      updatedItems[existingItemIndex].quantity += 1;
      setCartItems(updatedItems);
    } else {
      setCartItems((prev) => [...prev, newItem]);
    }

    setIsCartOpen(true);
  };

  const formatPrice = (price, currency) => {
    if (!price || !currency) return t("product.price");

    const formatter = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
    });

    return formatter.format(price);
  };

  const handleShare = (platform) => {
    const url = window.location.href;
    const text = `${product.name}`;

    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            url
          )}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            url
          )}&text=${encodeURIComponent(text)}`,
          "_blank"
        );
        break;
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(
            text + " " + url
          )}`,
          "_blank"
        );
        break;
      case "copy":
        navigator.clipboard.writeText(url).then(() => {
          setLinkCopied(true);
          setTimeout(() => setLinkCopied(false), 2000);
        });
        break;
      default:
        break;
    }
    setShowShareOptions(false);
  };

  if (loading) return <div className="loading">Yükleniyor...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!product) return <div className="error">Ürün bulunamadı!</div>;

  return (
    <>
      <div className="product-detail">
        <div className="product-detail-container">
          <div className="product-gallery">
            {renderMedia()}
            <div className="thumbnails">
              {product?.allMedia?.map((media, index) => (
                <div
                  key={index}
                  className={`thumbnail ${
                    currentMediaIndex === index ? "active" : ""
                  }`}
                  onClick={() => setCurrentMediaIndex(index)}
                >
                  {media.type === "video" ? (
                    <div className="video-thumbnail">
                      <video src={media.url} className="thumbnail-media" />
                      <span className="video-icon">▶</span>
                    </div>
                  ) : (
                    <img src={media.url} alt="" className="thumbnail-media" />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="product-info-detailed">
            <h1>{product.name}</h1>
            <div className="price-section">
              <span className="price">
                {product.price
                  ? formatPrice(
                      product.price.unit_amount,
                      product.price.currency
                    )
                  : t("product.price")}
              </span>

              <div className="shipping-info-section">
                {product.stock > 0 ? (
                  <>
                    <div className="stock-status in-stock">
                      <i className="fas fa-check-circle"></i>
                      <span>{t("product.shipping.inStock")}</span>
                    </div>
                    <div className="shipping-calculation">
                      <span>{t("product.shipping.calculatedAtCheckout")}</span>
                      <button className="learn-more-btn">
                        {t("product.shipping.learnMore")}
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="stock-status out-of-stock">
                    <i className="fas fa-times-circle"></i>
                    <span>{t("product.shipping.outOfStock")}</span>
                    <p>{t("product.shipping.notAvailable")}</p>
                  </div>
                )}
              </div>

              <button
                className="purchase-button"
                onClick={handlePurchase}
                disabled={!product || product.stock < 1}
              >
                {t("product.addToCart")}
              </button>
              <div className="share-container">
                <button
                  className="share-button"
                  onClick={() => setShowShareOptions(!showShareOptions)}
                >
                  <i className="fas fa-share-alt"></i> {t("product.share")}
                </button>
                {showShareOptions && (
                  <div className="share-options">
                    <button onClick={() => handleShare("facebook")}>
                      <i className="fab fa-facebook"></i>{" "}
                      {t("product.shareOn.facebook")}
                    </button>
                    <button onClick={() => handleShare("twitter")}>
                      <i className="fab fa-twitter"></i>{" "}
                      {t("product.shareOn.twitter")}
                    </button>
                    <button onClick={() => handleShare("whatsapp")}>
                      <i className="fab fa-whatsapp"></i>{" "}
                      {t("product.shareOn.whatsapp")}
                    </button>
                    <button onClick={() => handleShare("copy")}>
                      <i className="fas fa-link"></i>{" "}
                      {t("product.shareOn.copyLink")}
                    </button>
                    {linkCopied && (
                      <span className="copy-notification">
                        {t("product.shareOn.linkCopied")}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-details-section">
        <div className="product-details-content">
          <div className="product-description">
            <h2>{t("product.description")}</h2>
            <p>{product.description}</p>
          </div>

          <div className="product-specifications">
            <h2>{t("product.specifications")}</h2>
            <div className="specs-grid">
              {product.collection && (
                <div className="spec-item">
                  <span className="spec-label">{t("product.collection")}:</span>
                  <span className="spec-value">{product.collection}</span>
                </div>
              )}

              {product.scale && (
                <div className="spec-item">
                  <span className="spec-label">{t("product.scale")}:</span>
                  <span className="spec-value">{t("product.scaleText")}</span>
                </div>
              )}

              {product.productSize && (
                <div className="spec-item">
                  <span className="spec-label">
                    {t("product.dimensionsTitle")}:
                  </span>
                  <span className="spec-value">
                    {`${t("product.height")}: ${
                      product.productSize.match(/Height: (.*?) •/)?.[1] || ""
                    }`}
                    <br />
                    {`${t("product.width")}: ${
                      product.productSize.match(/Width: (.*?) •/)?.[1] || ""
                    }`}
                    <br />
                    {`${t("product.depth")}: ${
                      product.productSize.match(/Depth: (.*?)$/)?.[1] || ""
                    }`}
                  </span>
                </div>
              )}

              {product.productWeight && (
                <div className="spec-item">
                  <span className="spec-label">{t("product.weight")}:</span>
                  <span className="spec-value">
                    {`${product.productWeight} ${t("product.weightUnit")}`}
                  </span>
                </div>
              )}

              {product.materials && (
                <div className="spec-item">
                  <span className="spec-label">{t("product.materials")}:</span>
                  <span className="spec-value">{product.materials}</span>
                </div>
              )}
            </div>
          </div>

          <div className="additional-info">
            <div className="info-section shipping-info">
              <h2>{t("product.shippingInfo")}</h2>
              <div className="info-content">
                <div className="info-item">
                  <i className="fas fa-truck"></i>
                  <div className="info-text">
                    <h3>{t("product.worldwideShipping")}</h3>
                    <p>{t("product.shippingDescription")}</p>
                  </div>
                </div>
                <div className="info-item">
                  <i className="fas fa-box"></i>
                  <div className="info-text">
                    <h3>{t("product.securePackaging")}</h3>
                    <p>{t("product.packagingDescription")}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="info-section product-care">
              <h2>{t("product.productCare")}</h2>
              <div className="info-content">
                <div className="info-item">
                  <i className="fas fa-hand-holding-heart"></i>
                  <div className="info-text">
                    <h3>{t("product.handling")}</h3>
                    <p>{t("product.handlingDescription")}</p>
                  </div>
                </div>
                <div className="info-item">
                  <i className="fas fa-brush"></i>
                  <div className="info-text">
                    <h3>{t("product.cleaning")}</h3>
                    <p>{t("product.cleaningDescription")}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="info-section authenticity">
              <h2>{t("product.authenticity")}</h2>
              <div className="info-content">
                <div className="info-item">
                  <i className="fas fa-certificate"></i>
                  <div className="info-text">
                    <h3>{t("product.certificate")}</h3>
                    <p>{t("product.certificateDescription")}</p>
                  </div>
                </div>
                <div className="info-item">
                  <i className="fas fa-shield-alt"></i>
                  <div className="info-text">
                    <h3>{t("product.warranty")}</h3>
                    <p>{t("product.warrantyDescription")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="media-modal" onClick={handleModalClose}>
          <div className="modal-media-container">
            {product.allMedia[modalMediaIndex].type === "video" ? (
              <video
                ref={modalVideoRef}
                src={product.allMedia[modalMediaIndex].url}
                className="modal-media video"
                controls
                autoPlay
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <img
                src={product.allMedia[modalMediaIndex].url}
                alt=""
                className="modal-media image"
                onClick={(e) => e.stopPropagation()}
              />
            )}
          </div>
          <button
            className="modal-nav prev"
            onClick={(e) => {
              e.stopPropagation();
              handleModalNavigation("prev");
            }}
          >
            ‹
          </button>
          <button
            className="modal-nav next"
            onClick={(e) => {
              e.stopPropagation();
              handleModalNavigation("next");
            }}
          >
            ›
          </button>
          <button className="close-modal" onClick={handleModalClose}>
            ×
          </button>
        </div>
      )}
    </>
  );
};

ProductDetail.propTypes = {
  setIsCartOpen: PropTypes.func.isRequired,
  cartItems: PropTypes.array,
  setCartItems: PropTypes.func.isRequired,
};

export default ProductDetail;

import React, { useState, useEffect, StrictMode } from "react";
import { db, storage } from "../../../firebase/config";
import {
  collection,
  addDoc,
  getDocs,
  serverTimestamp,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import "./Products.css";
import { getAuth } from "firebase/auth";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import imageCompression from "browser-image-compression";

// Ülke isimlerini getiren yardımcı fonksiyon - Component dışında tanımlayalım
const getCountryName = (countryCode) => {
  const countries = {
    turkey: "Türkiye",
    germany: "Almanya",
    france: "Fransa",
    uk: "İngiltere",
    usa: "Amerika",
    netherlands: "Hollanda",
    belgium: "Belçika",
    italy: "İtalya",
    spain: "İspanya",
    portugal: "Portekiz",
    switzerland: "İsviçre",
    austria: "Avusturya",
    sweden: "İsveç",
    norway: "Norveç",
    denmark: "Danimarka",
    finland: "Finlandiya",
    greece: "Yunanistan",
    poland: "Polonya",
    russia: "Rusya",
    japan: "Japonya",
    china: "Çin",
    australia: "Avustralya",
    canada: "Kanada",
    brazil: "Brezilya",
    uae: "Birleşik Arap Emirlikleri",
  };
  return countries[countryCode] || countryCode;
};

// StrictMode için özel bir wrapper component oluşturalım
const DragDropContextContainer = ({ onDragEnd, children }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>;
};

// Görsel sıkıştırma fonksiyonu
const compressImage = async (file) => {
  const options = {
    maxSizeMB: 1, // maksimum 1MB
    maxWidthOrHeight: 1920, // maksimum genişlik/yükseklik
    useWebWorker: true, // Web Worker kullanarak performansı artır
    initialQuality: 0.8, // başlangıç kalitesi
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error("Görsel sıkıştırma hatası:", error);
    return file; // Sıkıştırma başarısız olursa orijinal dosyayı kullan
  }
};

const Products = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    categoryId: "",
    images: [],
    thumbnailIndex: 0,
    imageOrder: [],
    video: null,
    stock: 0,
    collection: "",
    scale: "",
    productSize: "",
    productWeight: "",
    materials: "",
    shipping: {
      countries: {},
    },
    price: {
      currency: "TRY",
      unit_amount: 0,
      active: true,
      type: "one_time",
    },
  });
  const [uploadProgress, setUploadProgress] = useState({
    images: {},
    video: 0,
  });
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    images: {},
    video: "",
  });
  const [newCountry, setNewCountry] = useState({ code: "", name: "" });
  const [showAddCountry, setShowAddCountry] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("ptt");
  const [showCountryDropdown, setShowCountryDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const shippingProviders = [
    { id: "ptt", name: "PTT Kargo" },
    { id: "ups", name: "UPS" },
    { id: "dhl", name: "DHL" },
  ];

  const countries = [
    { code: "TR", name: "Türkiye" },
    { code: "DE", name: "Almanya" },
    { code: "FR", name: "Fransa" },
    { code: "GB", name: "İngiltere" },
    { code: "US", name: "Amerika Birleşik Devletleri" },
    { code: "IT", name: "İtalya" },
    { code: "ES", name: "İspanya" },
    { code: "NL", name: "Hollanda" },
    { code: "BE", name: "Belçika" },
    { code: "AT", name: "Avusturya" },
    { code: "CH", name: "İsviçre" },
    { code: "SE", name: "İsveç" },
    { code: "DK", name: "Danimarka" },
    { code: "NO", name: "Norveç" },
    { code: "FI", name: "Finlandiya" },
    { code: "PT", name: "Portekiz" },
    { code: "IE", name: "İrlanda" },
    { code: "PL", name: "Polonya" },
    { code: "GR", name: "Yunanistan" },
    { code: "CZ", name: "Çek Cumhuriyeti" },
  ];

  // Yükleme göstergesi bileşeni
  const LoadingIndicator = () => (
    <div className="loading-indicator">
      <div className="spinner"></div>
      <p>Ürünler yükleniyor, lütfen bekleyin...</p>
    </div>
  );

  const fetchProducts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsData);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();

    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const categoriesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const ordersRef = collection(db, "orders");
    // Basitleştirilmiş sorgu
    const q = query(ordersRef, orderBy("createdAt", "desc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newOrdersList = [];
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          newOrdersList.push({
            id: change.doc.id,
            ...change.doc.data(),
          });
          setNotifications((prev) => [
            {
              id: change.doc.id,
              type: "new-order",
              message: `Yeni sipariş: ${change.doc.data().orderNumber}`,
              time: new Date(),
            },
            ...prev,
          ]);
        }
      });
      setNewOrders(newOrdersList);
    });

    return () => unsubscribe();
  }, []);

  const handleProductSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Tüm gerekli alanların dolu olduğundan emin olalım
      if (
        !newProduct.name ||
        !newProduct.price.unit_amount ||
        !newProduct.description
      ) {
        alert("Lütfen tüm zorunlu alanları doldurun.");
        setLoading(false);
        return;
      }

      // Görsel kontrolü
      if (newProduct.images.length === 0) {
        alert("En az bir ürün görseli eklemelisiniz.");
        setLoading(false);
        return;
      }

      // Firestore'a eklenecek veriyi hazırla
      const productData = {
        name: newProduct.name,
        description: newProduct.description,
        categoryId: newProduct.categoryId,
        images: newProduct.images.map((img) => img.url),
        thumbnailIndex: newProduct.thumbnailIndex,
        video: newProduct.video?.url || null,
        stock: parseInt(newProduct.stock),
        collection: newProduct.collection,
        scale: newProduct.scale,
        productSize: newProduct.productSize,
        productWeight: newProduct.productWeight,
        materials: newProduct.materials,
        shipping: newProduct.shipping,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      // Firestore'a ürünü ekle
      const docRef = await addDoc(collection(db, "products"), productData);

      // Fiyat verisini ekle
      const priceData = {
        currency: newProduct.price.currency,
        unit_amount: parseInt(newProduct.price.unit_amount),
        active: newProduct.price.active,
        type: newProduct.price.type,
        createdAt: serverTimestamp(),
      };

      await addDoc(collection(db, `products/${docRef.id}/prices`), priceData);

      // Başarılı mesajı göster
      alert("Ürün başarıyla eklendi!");

      // Formu sıfırla
      setNewProduct({
        name: "",
        description: "",
        categoryId: "",
        images: [],
        thumbnailIndex: 0,
        imageOrder: [],
        video: null,
        stock: 0,
        collection: "",
        scale: "",
        productSize: "",
        productWeight: "",
        materials: "",
        shipping: {
          countries: {},
        },
        price: {
          currency: "TRY",
          unit_amount: 0,
          active: true,
          type: "one_time",
        },
      });

      // Progress ve status'ları sıfırla
      setUploadProgress({ images: {}, video: 0 });
      setUploadStatus({ images: {}, video: "" });

      // Ürünleri yeniden yükle
      fetchProducts();
    } catch (error) {
      console.error("Error adding product:", error);
      alert("Ürün eklenirken bir hata oluştu: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Sürükle-bırak işlevleri
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.add("dragging");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove("dragging");
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove("dragging");

    const files = Array.from(e.dataTransfer.files);

    if (type === "image") {
      const imageFiles = files.filter((file) => file.type.startsWith("image/"));
      if (imageFiles.length > 0) {
        setNewProduct((prev) => {
          const newImages = [...prev.images, ...imageFiles];

          // Progress ve status güncellemelerini burada yapıyoruz
          const newProgress = { ...uploadProgress.images };
          const newStatus = { ...uploadStatus.images };
          imageFiles.forEach((_, index) => {
            const imageIndex = prev.images.length + index;
            newProgress[imageIndex] = 0;
            newStatus[imageIndex] = "";
          });
          setUploadProgress((prev) => ({ ...prev, images: newProgress }));
          setUploadStatus((prev) => ({ ...prev, images: newStatus }));

          return {
            ...prev,
            images: newImages,
          };
        });
      }
    } else if (type === "video") {
      const videoFile = files.find((file) => file.type.startsWith("video/"));
      if (videoFile) {
        setNewProduct((prev) => ({
          ...prev,
          video: videoFile,
        }));
      }
    }
  };

  // Dosya seçimi işlevi
  const handleFileSelect = async (e, type) => {
    const files = Array.from(e.target.files);

    if (type === "image") {
      const maxImageSize = 100 * 1024 * 1024;
      const invalidFiles = files.filter((file) => file.size > maxImageSize);

      if (invalidFiles.length > 0) {
        setUploadStatus((prev) => ({
          ...prev,
          images: {
            ...prev.images,
            error:
              "Bazı görseller 100MB'dan büyük. Lütfen daha küçük dosyalar seçin.",
          },
        }));
        return;
      }

      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error("Kullanıcı oturumu bulunamadı!");
      }

      // Tüm görselleri paralel olarak yükle
      const uploadPromises = files.map(async (file) => {
        try {
          setUploadStatus((prev) => ({
            ...prev,
            images: {
              ...prev.images,
              [file.name]: "Sıkıştırılıyor...",
            },
          }));

          // Görseli sıkıştır
          const compressedFile = await compressImage(file);

          setUploadStatus((prev) => ({
            ...prev,
            images: {
              ...prev.images,
              [file.name]: `Yüklemeye hazırlanıyor... (${(
                compressedFile.size /
                (1024 * 1024)
              ).toFixed(2)} MB)`,
            },
          }));

          const imageRef = ref(
            storage,
            `products/${user.uid}/${Date.now()}_${file.name}`
          );

          // Yükleme işlemini başlat
          const uploadTask = uploadBytesResumable(imageRef, compressedFile);

          // Promise içinde yükleme işlemini takip et
          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const uploadedMB = (
                  snapshot.bytesTransferred /
                  (1024 * 1024)
                ).toFixed(2);
                const totalMB = (snapshot.totalBytes / (1024 * 1024)).toFixed(
                  2
                );

                setUploadProgress((prev) => ({
                  ...prev,
                  images: { ...prev.images, [file.name]: progress },
                }));

                setUploadStatus((prev) => ({
                  ...prev,
                  images: {
                    ...prev.images,
                    [file.name]: `Yükleniyor... ${uploadedMB}MB / ${totalMB}MB (${Math.round(
                      progress
                    )}%)`,
                  },
                }));
              },
              (error) => {
                console.error("Upload error:", error);
                reject(error);
              },
              async () => {
                try {
                  const url = await getDownloadURL(uploadTask.snapshot.ref);
                  resolve({ url, name: file.name, size: compressedFile.size });
                } catch (error) {
                  reject(error);
                }
              }
            );
          });
        } catch (error) {
          console.error(`Error processing file ${file.name}:`, error);
          throw error;
        }
      });

      // Tüm yüklemeleri paralel olarak bekle
      try {
        const uploadedImages = await Promise.all(uploadPromises);

        // Tüm görseller yüklendikten sonra state'i güncelle
        setNewProduct((prev) => ({
          ...prev,
          images: [...prev.images, ...uploadedImages],
          imageOrder: [
            ...prev.imageOrder,
            ...uploadedImages.map((_, index) => prev.images.length + index),
          ],
        }));

        setUploadStatus((prev) => ({
          ...prev,
          images: {
            ...prev.images,
            success: "Tüm görseller başarıyla yüklendi!",
          },
        }));
      } catch (error) {
        console.error("Yükleme hatası:", error);
        setUploadStatus((prev) => ({
          ...prev,
          images: {
            ...prev.images,
            error: "Yükleme sırasında hata oluştu: " + error.message,
          },
        }));
      }
    } else if (type === "video") {
      const file = files[0];
      const maxVideoSize = 300 * 1024 * 1024; // 300MB

      if (file.size > maxVideoSize) {
        setUploadStatus((prev) => ({
          ...prev,
          video: "Hata: Video boyutu 300MB'dan küçük olmalıdır",
        }));
        return;
      }

      try {
        setUploadStatus((prev) => ({
          ...prev,
          video: `Yüklemeye hazırlanıyor... (${(
            file.size /
            (1024 * 1024)
          ).toFixed(2)} MB)`,
        }));
        setUploadProgress((prev) => ({ ...prev, video: 0 }));

        const auth = getAuth();
        const user = auth.currentUser;
        const videoRef = ref(
          storage,
          `products/${user.uid}/videos/${Date.now()}_${file.name}`
        );

        // Video önizlemesi için
        setNewProduct((prev) => ({
          ...prev,
          video: {
            file,
            name: file.name,
            size: file.size,
            previewUrl: URL.createObjectURL(file),
          },
        }));

        const uploadTask = uploadBytesResumable(videoRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const uploadedMB = (
              snapshot.bytesTransferred /
              (1024 * 1024)
            ).toFixed(2);
            const totalMB = (snapshot.totalBytes / (1024 * 1024)).toFixed(2);

            setUploadProgress((prev) => ({
              ...prev,
              video: progress,
            }));

            setUploadStatus((prev) => ({
              ...prev,
              video: `Yükleniyor... ${uploadedMB}MB / ${totalMB}MB (${Math.round(
                progress
              )}%)`,
            }));
          },
          (error) => {
            console.error("Video upload error:", error);
            setUploadStatus((prev) => ({
              ...prev,
              video: `Hata: ${error.message}`,
            }));
            setNewProduct((prev) => ({ ...prev, video: null }));
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              setNewProduct((prev) => ({
                ...prev,
                video: {
                  ...prev.video,
                  url,
                },
              }));
              setUploadStatus((prev) => ({
                ...prev,
                video: "Video başarıyla yüklendi!",
              }));
            } catch (error) {
              console.error("Video URL error:", error);
              setUploadStatus((prev) => ({
                ...prev,
                video: `Hata: ${error.message}`,
              }));
            }
          }
        );
      } catch (error) {
        console.error("Video upload error:", error);
      }
    }
  };

  // Görsel sıralama işlevi
  const handleReorderImages = (fromIndex, toIndex) => {
    setNewProduct((prev) => {
      const newOrder = [...prev.imageOrder];
      const [movedItem] = newOrder.splice(fromIndex, 1);
      newOrder.splice(toIndex, 0, movedItem);
      return {
        ...prev,
        imageOrder: newOrder,
        thumbnailIndex:
          prev.thumbnailIndex === fromIndex ? toIndex : prev.thumbnailIndex,
      };
    });
  };

  // Thumbnail seçme işlevi
  const handleThumbnailSelect = (index) => {
    setNewProduct((prev) => ({
      ...prev,
      thumbnailIndex: index,
    }));
  };

  // Görsel silme işlevi
  const handleRemoveImage = (indexToRemove) => {
    setNewProduct((prev) => {
      const newImages = prev.images.filter(
        (_, index) => index !== indexToRemove
      );
      const newImageOrder = prev.imageOrder
        .filter((index) => index !== indexToRemove)
        .map((index) => (index > indexToRemove ? index - 1 : index));

      return {
        ...prev,
        images: newImages,
        imageOrder: newImageOrder,
        thumbnailIndex:
          prev.thumbnailIndex === indexToRemove
            ? 0
            : prev.thumbnailIndex > indexToRemove
            ? prev.thumbnailIndex - 1
            : prev.thumbnailIndex,
      };
    });

    // Progress ve status'ları güncelle
    setUploadProgress((prev) => {
      const newImages = { ...prev.images };
      delete newImages[indexToRemove];
      return { ...prev, images: newImages };
    });

    setUploadStatus((prev) => {
      const newImages = { ...prev.images };
      delete newImages[indexToRemove];
      return { ...prev, images: newImages };
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(newProduct.images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    let newThumbnailIndex = newProduct.thumbnailIndex;
    if (newProduct.thumbnailIndex === result.source.index) {
      newThumbnailIndex = result.destination.index;
    } else if (
      result.source.index < newProduct.thumbnailIndex &&
      result.destination.index >= newProduct.thumbnailIndex
    ) {
      newThumbnailIndex--;
    } else if (
      result.source.index > newProduct.thumbnailIndex &&
      result.destination.index <= newProduct.thumbnailIndex
    ) {
      newThumbnailIndex++;
    }

    setNewProduct((prev) => ({
      ...prev,
      images: items,
      thumbnailIndex: newThumbnailIndex,
    }));
  };

  // Dosya seçme penceresini açma işlevi
  const triggerFileInput = (inputId) => {
    document.getElementById(inputId).click();
  };

  // Yeni ülke ekleme fonksiyonu
  const handleAddCountry = () => {
    if (newCountry.code && newCountry.name) {
      setNewProduct((prev) => ({
        ...prev,
        shipping: {
          ...prev.shipping,
          countries: {
            ...prev.shipping.countries,
            [newCountry.code.toLowerCase()]: {
              standard: {
                price: 0,
                estimatedDays: "5-7",
              },
              express: {
                price: 0,
                estimatedDays: "2-3",
              },
            },
          },
        },
      }));
      setNewCountry({ code: "", name: "" });
      setShowAddCountry(false);
    }
  };

  const handleCountrySelect = (country) => {
    setNewProduct((prev) => ({
      ...prev,
      shipping: {
        ...prev.shipping,
        countries: {
          ...prev.shipping.countries,
          [country.code.toLowerCase()]: {
            price: 0,
            estimatedDays: "",
          },
        },
      },
    }));
  };

  const handleShippingRateChange = (code, field, value) => {
    setNewProduct((prev) => ({
      ...prev,
      shipping: {
        ...prev.shipping,
        countries: {
          ...prev.shipping.countries,
          [code]: {
            ...prev.shipping.countries[code],
            [field]: value,
          },
        },
      },
    }));
  };

  return (
    <div className="products-container">
      <div className="products-header">
        <h2>Ürün Yönetimi</h2>
      </div>

      {loading && <LoadingIndicator />}

      <div
        className="product-form-container"
        style={{ position: "relative", zIndex: 2 }}
      >
        <h3 className="form-title">Yeni Ürün Ekle</h3>
        <form
          onSubmit={handleProductSubmit}
          className="product-form"
          style={{ position: "relative", zIndex: 3 }}
        >
          <div className="form-group">
            <label>Ürün Adı</label>
            <input
              type="text"
              value={newProduct.name}
              onChange={(e) =>
                setNewProduct({ ...newProduct, name: e.target.value })
              }
              placeholder="Ürün adını girin"
              required
            />
          </div>

          <div className="form-group">
            <label>Kategori</label>
            <select
              value={newProduct.categoryId}
              onChange={(e) =>
                setNewProduct({ ...newProduct, categoryId: e.target.value })
              }
              required
            >
              <option value="">Kategori Seçin</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Para Birimi</label>
            <select
              value={newProduct.price.currency}
              onChange={(e) =>
                setNewProduct({
                  ...newProduct,
                  price: { ...newProduct.price, currency: e.target.value },
                })
              }
              required
            >
              <option value="TRY">TRY (₺)</option>
              <option value="USD">USD ($)</option>
              <option value="EUR">EUR (€)</option>
            </select>
          </div>

          <div className="form-group">
            <label>Fiyat (Unit Amount)</label>
            <input
              type="number"
              step="1"
              min="0"
              value={newProduct.price.unit_amount}
              onChange={(e) =>
                setNewProduct({
                  ...newProduct,
                  price: { ...newProduct.price, unit_amount: e.target.value },
                })
              }
              placeholder="0"
              required
            />
          </div>

          <div className="form-group">
            <label>Stok Miktarı</label>
            <input
              type="number"
              min="0"
              value={newProduct.stock}
              onChange={(e) =>
                setNewProduct({ ...newProduct, stock: e.target.value })
              }
              placeholder="0"
              required
            />
          </div>

          <div className="form-group full-width">
            <label>Ürün Açıklaması</label>
            <textarea
              value={newProduct.description}
              onChange={(e) =>
                setNewProduct({ ...newProduct, description: e.target.value })
              }
              placeholder="Ürün detaylarını girin"
              required
            />
          </div>

          <div className="form-group">
            <label>Ürün Görselleri</label>
            <div className="image-upload-container">
              {/* Yeni görsel ekleme kutusu */}
              <div className="image-upload-box">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileSelect(e, "image")}
                  id="image-upload"
                  style={{ display: "none" }}
                  multiple
                />
                <div
                  className="upload-placeholder"
                  onClick={() =>
                    document.getElementById("image-upload").click()
                  }
                >
                  <i className="fas fa-plus"></i>
                  <span>Yeni Görsel Ekle</span>
                </div>
              </div>

              {/* Yüklenen görseller */}
              <div className="uploaded-images">
                {newProduct.imageOrder.map((index, orderIndex) => {
                  const image = newProduct.images[index];
                  return (
                    <div
                      key={image.name}
                      className={`image-item ${
                        newProduct.thumbnailIndex === index ? "thumbnail" : ""
                      }`}
                    >
                      <img src={image.url} alt={`Product ${orderIndex + 1}`} />
                      <div className="image-controls">
                        <button
                          type="button"
                          onClick={() => handleThumbnailSelect(index)}
                          className={`thumbnail-btn ${
                            newProduct.thumbnailIndex === index ? "active" : ""
                          }`}
                        >
                          <i className="fas fa-star"></i>
                        </button>
                        <button
                          type="button"
                          onClick={() => handleRemoveImage(index)}
                          className="remove-btn"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                      <div className="image-order">
                        <button
                          type="button"
                          onClick={() =>
                            handleReorderImages(
                              orderIndex,
                              Math.max(0, orderIndex - 1)
                            )
                          }
                          disabled={orderIndex === 0}
                        >
                          <i className="fas fa-arrow-up"></i>
                        </button>
                        <span>{orderIndex + 1}</span>
                        <button
                          type="button"
                          onClick={() =>
                            handleReorderImages(
                              orderIndex,
                              Math.min(
                                newProduct.images.length - 1,
                                orderIndex + 1
                              )
                            )
                          }
                          disabled={orderIndex === newProduct.images.length - 1}
                        >
                          <i className="fas fa-arrow-down"></i>
                        </button>
                      </div>
                      {uploadProgress.images[image.name] < 100 && (
                        <div className="upload-progress">
                          <div
                            className="progress-bar"
                            style={{
                              width: `${uploadProgress.images[image.name]}%`,
                            }}
                          />
                          <span>
                            {Math.round(uploadProgress.images[image.name])}%
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Video (Opsiyonel)</label>
            <div className="upload-box">
              <input
                type="file"
                accept="video/*"
                onChange={(e) => handleFileSelect(e, "video")}
                id="video-upload"
                style={{ display: "none" }}
              />
              <div className="upload-content">
                {newProduct.video ? (
                  <div className="video-preview-container">
                    <div className="video-preview">
                      <video
                        src={
                          newProduct.video.previewUrl || newProduct.video.url
                        }
                        controls
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                      <div className="video-info">
                        <span className="file-name">
                          {newProduct.video.name}
                        </span>
                        <span className="file-size">
                          {(newProduct.video.size / 1024 / 1024).toFixed(2)} MB
                        </span>
                      </div>
                      <div className="upload-status-container">
                        {uploadProgress.video < 100 && (
                          <div className="upload-progress">
                            <div
                              className="progress-bar"
                              style={{ width: `${uploadProgress.video}%` }}
                            />
                            <div className="progress-info">
                              <div className="progress-percentage">
                                {Math.round(uploadProgress.video)}%
                              </div>
                              <div className="progress-status">
                                {uploadStatus.video}
                              </div>
                            </div>
                          </div>
                        )}
                        {uploadStatus.video && uploadProgress.video === 100 && (
                          <div
                            className={`upload-status ${
                              uploadStatus.video.includes("Hata")
                                ? "error"
                                : uploadStatus.video.includes("başarıyla")
                                ? "success"
                                : ""
                            }`}
                          >
                            <span>{uploadStatus.video}</span>
                          </div>
                        )}
                      </div>
                      <button
                        type="button"
                        className="remove-video"
                        onClick={() => {
                          if (newProduct.video.previewUrl) {
                            URL.revokeObjectURL(newProduct.video.previewUrl);
                          }
                          setNewProduct((prev) => ({ ...prev, video: null }));
                          setUploadProgress((prev) => ({ ...prev, video: 0 }));
                          setUploadStatus((prev) => ({ ...prev, video: "" }));
                        }}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="upload-placeholder"
                    onClick={() => triggerFileInput("video-upload")}
                  >
                    <i className="fas fa-video"></i>
                    <span>Video eklemek için tıklayın</span>
                    <small>MP4, WebM (max. 250MB)</small>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Koleksiyon</label>
            <input
              type="text"
              value={newProduct.collection}
              onChange={(e) =>
                setNewProduct((prev) => ({
                  ...prev,
                  collection: e.target.value,
                }))
              }
              placeholder="Koleksiyon adını girin"
            />
          </div>

          <div className="form-group">
            <label>Ölçek</label>
            <input
              type="text"
              value={newProduct.scale}
              onChange={(e) =>
                setNewProduct((prev) => ({ ...prev, scale: e.target.value }))
              }
              placeholder="Ürün ölçeğini girin"
            />
          </div>

          <div className="form-group">
            <label>Ürün Boyutu</label>
            <input
              type="text"
              value={newProduct.productSize}
              onChange={(e) =>
                setNewProduct((prev) => ({
                  ...prev,
                  productSize: e.target.value,
                }))
              }
              placeholder="Ürün boyutunu girin"
            />
          </div>

          <div className="form-group">
            <label>Ürün Ağırlığı</label>
            <input
              type="text"
              value={newProduct.productWeight}
              onChange={(e) =>
                setNewProduct((prev) => ({
                  ...prev,
                  productWeight: e.target.value,
                }))
              }
              placeholder="Ürün ağırlığını girin"
            />
          </div>

          <div className="form-group">
            <label>Malzemeler</label>
            <input
              type="text"
              value={newProduct.materials}
              onChange={(e) =>
                setNewProduct((prev) => ({
                  ...prev,
                  materials: e.target.value,
                }))
              }
              placeholder="Ürün malzemelerini girin"
            />
          </div>

          <div className="form-group full-width">
            <label>Kargo Ayarları</label>
            <div className="shipping-settings">
              <div className="country-select-container">
                <div
                  className="country-select"
                  onClick={() => setShowCountryDropdown(!showCountryDropdown)}
                >
                  {selectedCountry ? selectedCountry.name : "Ülke Seçin"}
                </div>

                {showCountryDropdown && (
                  <div className="country-dropdown">
                    {countries.map((country) => (
                      <div
                        key={country.code}
                        className="country-option"
                        onClick={() => {
                          setSelectedCountry(country);
                          setShowCountryDropdown(false);
                        }}
                      >
                        {country.name}
                      </div>
                    ))}
                  </div>
                )}

                <button
                  type="button"
                  className="add-shipping-button"
                  onClick={() => {
                    if (selectedCountry) {
                      handleCountrySelect(selectedCountry);
                      setSelectedCountry(null);
                    }
                  }}
                >
                  Ekle
                </button>
              </div>

              <div className="shipping-rates">
                {Object.entries(newProduct.shipping.countries).map(
                  ([code, rates]) => (
                    <div key={code} className="shipping-rate-item">
                      <div className="shipping-rate-header">
                        <span className="shipping-rate-title">
                          {
                            countries.find((c) => c.code.toLowerCase() === code)
                              ?.name
                          }
                        </span>
                      </div>
                      <div className="shipping-inputs">
                        <input
                          type="number"
                          value={rates.price}
                          onChange={(e) =>
                            handleShippingRateChange(
                              code,
                              "price",
                              e.target.value
                            )
                          }
                          placeholder="Kargo Ücreti"
                        />
                        <input
                          type="text"
                          value={rates.estimatedDays}
                          onChange={(e) =>
                            handleShippingRateChange(
                              code,
                              "estimatedDays",
                              e.target.value
                            )
                          }
                          placeholder="Teslimat Süresi (örn: 1-3 gün)"
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="form-actions">
            <button type="submit" disabled={loading} className="submit-button">
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> Ekleniyor...
                </>
              ) : (
                <>
                  <i className="fas fa-plus"></i> Ürün Ekle
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Products;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./AboutUs.css";

function AboutUs() {
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/");
  };

  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <div className="language-sections">
          {/* İngilizce Bölüm */}
          <div className="about-section english">
            <h1>Who Are We?</h1>
            <div className="about-text">
              <p>
                Hello! I'm Onur Şimşek, living in Turkey. In 2020, inspired by
                the accessibility of 3D printers, I embarked on this journey to
                bring my passion for sculpting and model design to life.
              </p>
              <p>
                Initially, we started as a modest workshop offering 3D printing
                and painting services. Over time, we enhanced our technical
                expertise and artistic perspective, focusing on creating unique
                designs and meticulous craftsmanship. Today, we operate as a
                specialized polystone sculpture casting studio, designing and
                producing exclusive products for collectors and art enthusiasts.
              </p>
              <p>
                Our company also provides contract manufacturing services and
                takes pride in producing sculptures for licensed companies. Our
                team stands out with its dedication to art and detail in every
                piece it creates. Each of our sculptures carries a special story
                that reflects our passion and craftsmanship. By maintaining the
                highest standards of quality and aesthetics, we are proud to be
                part of the limitless world of art.
              </p>
              <p>
                With unwavering excitement from day one, we continue to create
                original designs and improve ourselves in this field. My dream
                is to transform our studio into a larger facility or a small
                factory, becoming a leading company in this industry. To achieve
                this, we always welcome support and are open to all kinds of
                investment opportunities. Please feel free to reach out to us.
              </p>
              <p className="signature">
                Thank you for joining us on this artistic journey!
              </p>
            </div>
          </div>

          {/* Türkçe Bölüm */}
          <div className="about-section turkish">
            <h1>Biz Kimiz?</h1>
            <div className="about-text">
              <p>
                Merhaba! Ben Onur Şimşek ve Türkiye'de yaşıyorum. 2020 yılında,
                3D yazıcıların herkesin erişebileceği bir seviyeye gelmesiyle
                birlikte, tutkum olan heykel ve model tasarımlarını hayata
                geçirme fikriyle bu yola çıktım.
              </p>

              <p>
                Başlangıçta, 3D baskı ve boyama hizmetleri sunan mütevazi bir
                dükkan olarak faaliyete geçtik. Ancak zaman içinde teknik bilgi
                birikimimizi ve sanatsal bakış açımızı geliştirerek, bugün özgün
                tasarımlarımızı ve detay odaklı üretimimizi odak noktasımıza
                aldık. Artık özelleşmiş bir polystone heykel döküm atölyesi
                olarak koleksiyoncular ve sanatseverler için benzersiz ürünler
                tasarlıyor ve üretiyoruz.
              </p>

              <p>
                Firmamız aynı zamanda fason üretim yapan bir firma olup,
                lisanslı firmaların heykellerini üretmekten gurur duymaktadır.
                Ekibimiz, yarattığı her parçada sanata ve detaya olan
                bağlılığıyla fark yaratıyor. Her bir heykelimiz, tutkumuzu ve
                zanaatkarlık anlayışımızı yansıtan özel bir hikâye taşıyor.
                Koleksiyonluk heykellerimizi hem kalite hem de estetik açıdan
                üst seviyede tutarak, sanatın sınırsız dünyasının bir parçası
                olmaktan gurur duyuyoruz.
              </p>

              <p>
                İlk günkü heyecanımızı kaybetmeden, özgün tasarımlar yaratmaya
                ve bu alanda kendimizi geliştirmeye devam ediyoruz. Hayalim,
                atölyemizi daha büyük bir stüdyo veya küçük bir fabrikaya
                dönüştürerek bu sektörde öncü firmalardan biri haline gelmek. Bu
                yolda işimizi büyütmek için her zaman desteğe ihtiyacımız var.
                Her türlü yatırım için bizimle iletişime geçebilirsiniz.
              </p>

              <p className="signature">
                Bizimle bu sanat yolculuğuna eşlik ettiğiniz için teşekkür
                ederiz!
              </p>
            </div>
          </div>
        </div>

        <button className="back-button" onClick={handleNavigateHome}>
          Ana Sayfaya Dön / Back to Home
        </button>
      </div>
    </div>
  );
}

export default AboutUs;

import React from "react";
import { Link } from "react-router-dom";
import "./ProductCard.css";

const ProductCard = ({ product }) => {
  return (
    <Link to={`/product/${product.id}`} className="product-card">
      <div className="product-image-container">
        <img
          src={product.images?.[0] || "placeholder.jpg"}
          alt={product.name}
          className="product-image"
        />
        <div className="product-info">
          <h3 className="product-title">{product.name}</h3>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;

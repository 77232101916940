import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Hero.css";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from "../../firebase/config";

const Hero = () => {
  const navigate = useNavigate();
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  // Varsayılan banner'ları tanımla
  const defaultBanners = [
    {
      image: "/banners/promo1.jpg",
      title: "Modern Sanat Eserleri",
      subtitle: "Özgün ve Etkileyici Figürler",
    },
    {
      image: "/banners/promo2.jpg",
      title: "Özel Koleksiyonlar",
      subtitle: "Benzersiz Sanat Deneyimi",
    },
  ];

  // Banner'ları getir
  const fetchBanners = useCallback(async () => {
    try {
      const bannersRef = collection(db, "heroBanners");
      const q = query(bannersRef, orderBy("createdAt", "desc"), limit(5)); // Sadece son 5 banner'ı getir
      const querySnapshot = await getDocs(q);
      const bannersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (bannersData.length > 0) {
        setBanners(bannersData);
      } else {
        setBanners(defaultBanners);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      setBanners(defaultBanners);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: "linear",
    lazyLoad: "progressive", // Lazy loading ekle
  };

  const handleBannerClick = (productId) => {
    if (productId) {
      navigate(`/product/${productId}`);
    }
  };

  if (loading) {
    return (
      <div className="hero-section">
        <div className="hero-loading">
          <div className="loading-shimmer"></div>
          <div className="loading-spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="hero-section">
      <div className="hero-background"></div>
      <Slider {...settings}>
        {banners.map((slide, index) => (
          <div
            key={slide.id || index}
            className="hero-slide"
            onClick={() => handleBannerClick(slide.productId)}
            style={{ cursor: slide.productId ? "pointer" : "default" }}
          >
            <div className="slide-content">
              <div className="slide-overlay"></div>
              <img
                src={slide.image}
                alt={slide.title}
                loading="lazy"
                onLoad={(e) => {
                  e.target.classList.add("loaded");
                }}
              />
              <div className="slide-text">
                <h1>{slide.title}</h1>
                <p>{slide.subtitle}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Hero;
